<template>
  <div class="cargoController-detail">
    <app-header v-if="title" :title="title" :isShowBack="true"></app-header>
        <div>
          <el-form ref="myForm" :model="myForm" :rules="rules">
              <el-form-item label-width="150px" label="货管员类型" prop="type">
                  <el-select v-model="myForm.type"  :disabled="notEdit || notAdd" @change="setTips(myForm.type)">
                      <el-option v-for="item in typeList" :key="item.value" :label="item.label" :value="item.value"></el-option>
                  </el-select>
                  <div class="select__tip">{{tip}}</div>
              </el-form-item>
              <el-form-item label-width="150px" label="货管员账号" prop="userName">
                  <el-input v-model="myForm.userName" :disabled="notEdit" placeholder="请输入货管员账号"></el-input>
              </el-form-item>
              <el-form-item label-width="150px" label="货管员密码" prop="password">
                  <el-input  class="no-autofill-pwd" type="text"  style=" display: inline-block" v-model.trim="myForm.password" :disabled="notEdit" placeholder="请输入货管员密码"></el-input>
              </el-form-item>
              <el-form-item label-width="150px" label="货管员姓名" prop="realName">
                  <el-input v-model="myForm.realName" :disabled="notEdit" placeholder="请输入货管员姓名"></el-input>
              </el-form-item>
              <el-form-item label-width="150px" label="货管员手机号" prop="phone">
                  <el-input v-model="myForm.phone" :disabled="notEdit" placeholder="请输入货管员手机号"></el-input>
              </el-form-item>
              <el-form-item v-if="showMerchant" label-width="150px" label="商家名称" prop="merchantId">
                <el-select v-if="!multiple" v-model="myForm.merchantId" :disabled="notEdit || notAdd" placeholder="请选择商家名称">
                    <el-option v-for="item in merchantList" :key="item.value" :label="item.label" :value="item.value"></el-option>
                </el-select>
                <el-select v-else v-model="myForm.merchantId" multiple  :disabled="notEdit || notAdd" placeholder="请选择商家名称">
                    <el-option v-for="item in merchantList" :key="item.value" :label="item.label" :value="item.value"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label-width="150px" label="状态" prop="state">
                <el-switch v-model="myForm.state" active-color="#13ce66" inactive-color="#ff4949" active-text="启用" inactive-text="禁用"></el-switch>
              </el-form-item>
              <div class="submitBox" v-show="!notEdit">
                <el-button style="width: 180px" type="primary" @click="submit()">提交</el-button>
            </div>
          </el-form>
        </div>
    <div v-if="showCargoInfo" style="font-size:18px;margin:100px 0 6px;padding-left:50px;color:#303133;">管理的货柜信息</div>
    <keep-alive v-if="showCargoInfo">
      <cargoInfo></cargoInfo>
    </keep-alive>
  </div>
</template>
<script>
  let Vue;
  let usernameVali = (rule, value, callback) => {
    // var reg = /(?=.{6,18})(?=.*)(?=.*[a-z])[\x20-\x7f]*/i;
    var reg = /^[a-zA-Z0-9]{6,16}$/;
    // if (value && value.length < 6) {
    //   callback(new Error("用户名不能小于6位字符"));
    // }
    // if (value && value.length > 16) {
    //   callback(new Error("用户名不能大于16位字符"));
    // }
    if (!reg.test(value)) {
      callback(
        new Error("用户名只能包含大小写字母与数字,长度6-16位")
      );
    } else {
      callback();
    }

  };

  let passwordVali = (rule, value, callback) => {
    if (value == "Aa1*******") {
      callback();
    }
    var reg = /[^A-z0-9`~!@#$%^&*()_\-+=<>?:"{}|,.\/;'\\[\]·]/g;
    var reg2 = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[^]{8,16}$/;
    if (reg.test(value)) {
      callback(new Error("密码只能包含大小写字母、数字、英文符号"));
    }
    if (!reg2.test(value)) {
      callback(new Error("密码必须最少包含字母、数字、1个大写字母、1个小写字母,长度8-16位"));
    } else {
      // var arr = ["", "低", "中", "高"]; // 获取对象
      //   var s = 0;
      //   if (/[a-zA-Z]/.test(value)) {
      //     s++;
      //   }
      //   if (/[0-9]/.test(value)) {
      //     s++;
      //   }
      //   if (/[^0-9a-zA-Z]/.test(value)) {
      //     s++;
      //   }
      // callback(arr[s]);
      callback();
    }
  };
  let validName = (rule, value, callback) => {
    //姓名支持输入中英文字母，汉字，1~16个字符
    let reg = /^[a-zA-Z\u4e00-\u9fa5]{1,16}$/;
    if (!reg.test(value)) {
      callback(new Error("货管员姓名格式不对"));
    } else {
      callback();
    }
  };
  let validMobile = (rule, value, callback) => {
    let reg = /^1[3456789]\d{9}$/;
    if (!reg.test(value)) {
      callback(new Error("货管员手机号格式不对"));
    } else {
      callback();
    }
  };
  import cargoInfo from './cargoInfo';
  export default {
    components: {
      cargoInfo,
    },
    data() {
      let _this = this;
      Vue = this;
      return {
        title: "货管员详情",
        notEdit: false,
        notAdd: false,
        showMerchant: true,
        oldPassword: '',
        multiple: false,
        myForm: {
            type: '0',
            userName: '',
            password: '',
            realName: '',
            merchantId: '',
            phone: '',
            state: false,
        },
        rules: {
            type: [{
              required: true,
              trigger: "change",
            }],
            userName: [{
              required: true,
              trigger: "change",
              validator: usernameVali,
            }],
            password: [{
              required: true,
              trigger: "change",
              validator: passwordVali,
            }],
            realName: [{
              required: true,
              trigger: "change",
              message: "请输入货管员姓名",
            }],
            phone: [{
              required: true,
              trigger: "change",
              validator: validMobile,
            }],
            merchantId: [{
              required: true,
              trigger: "change",
              message: "请选择商家名称",
            }]
        },
        merchantList:[],
        typeList:[{
            label: "货管员",
            value: "0",
        },
        {
            label: "小富",
            value: "1"
        },{
            label: "平台货管员",
            value: "2"
        }],
        tip: '支持补货、下架、移货、报损、查看货柜订单等全部功能。',
        showCargoInfo: false, //是否显示管理的货柜信息
        businessFlag: false,
      };
    },
    activated() {
      console.log("cargoController-detail created!!");
      this.businessFlag = this.getLoginType();
      if (this.businessFlag) {
        this.showMerchant = false
        this.typeList=[{
            label: "货管员",
            value: "0",
        },
        {
            label: "小富",
            value: "1"
        }]
      } else {
        this.showMerchant = true
        this.getMerchantList();
      }
      if (this.$route.params.type == "add") {
        this.notAdd = false
      } else {
        this.getDetail()
        this.notAdd = true
      }
      if (this.$route.query.type == "show") {
        this.showCargoInfo = true;
        this.notEdit = true
        this.rules = {}
        // 获取货柜信息
      } else {
        this.showCargoInfo = false;
        this.notEdit = false
        this.rules= {
            type: [{
              required: true,
              trigger: "change",
            }],
            userName: [{
              required: true,
              trigger: "change",
              validator: usernameVali,
            }],
            password: [{
              required: true,
              trigger: "change",
              validator: passwordVali,
            }],
            realName: [{
              required: true,
              trigger: "change",
              message: "请输入货管员姓名",
            }],
            phone: [{
              required: true,
              trigger: "change",
              validator: validMobile,
            }],
            merchantId: [{
              required: true,
              trigger: "change",
              message: "请选择商家名称",
            }]
        }
      }
      this.title = ["添加货管员", "编辑货管员", "货管员详情"][{
        add: 0,
        edit: 1,
        show: 2
      } [this.$route.params.type]];
      this.myForm= {
          type: '0',
          userName: '',
          password: '',
          realName: '',
          merchantId: '',
          phone: '',
          state: false,
      }
      this.$nextTick(() => {
          if (this.$refs["myForm"]) {
              this.$refs["myForm"].resetFields();
          }
      })
      this.tip = "支持补货、下架、移货、报损、查看货柜订单等全部功能。"
      this.multiple = false
    },
    methods: {
      getDetail(){
        var id = this.$route.query.id;
        this.get("mall-service/counterUser/detail/" + id).then(data => {
          // 保存原始账号
          this.myForm.password = "Aa1*******";
          this.myForm.type = data.type.toString();
          this.myForm.userName = data.userName;
          this.myForm.realName = data.realName;
          this.myForm.realName = data.realName;
          if(data.type == 2) {
            this.tip = "支持跨商家、跨门店补货、下架、移货、报损、查看货柜订单等功能。"
            this.myForm.merchantId = data.merchantId.split(',')
            this.multiple = true
          }else if(data.type == 1) {
            this.tip = "仅支持查看授权的货柜和补货功能。"
            this.myForm.merchantId = data.merchantId;
            this.multiple = false
          }else{
            this.tip = "支持补货、下架、移货、报损、查看货柜订单等全部功能。"
            this.myForm.merchantId = data.merchantId;
            this.multiple = false
          }
          this.myForm.phone = data.phone;
          this.oldPassword = this.myForm.password;
          this.myForm.state = data.state == 1 ? true : false;
        });
      },
      submit() {
        let dto = {
          type: this.myForm.type,
          userName: this.myForm.userName,
          password: this.myForm.password,
          realName: this.myForm.realName ? this.myForm.realName : "",
          state: this.myForm.state ? 1 : 2,
          phone: this.myForm.phone,
        };
        if (!this.businessFlag) {
          dto.merchantId = this.myForm.type == 2?this.myForm.merchantId.join(','):this.myForm.merchantId;
        } else {
          dto.merchantId = this.cache.getLS('userInfo').merchantId;
        }
        let url;
        let message;
        if (this.$route.query.type == "add") {
          url = "mall-service/counterUser/add";
          message = "添加货管员成功";
        } else if (this.$route.query.type == "edit") {
          url = "mall-service/counterUser/update";
          dto.id = this.$route.query.id;
          message = "编辑货管员成功";
        }
        if (this.myForm.username == this.myForm.password) {
          this.$message({
            message: "账号和密码不能相同",
            type: "warning"
          });
          return false;
        }
        if (this.oldPassword != this.myForm.password) {
          dto.password = this.myForm.password;
        } else {
          dto.password = "";
        }
        this.post(url, dto, {
          isUseResponse: true
        }).then(res => {
          if (res.data.data == "") {
            this.$message({
              showClose: true,
              message: message,
              type: "success"
            });
            this.$back();
          } else if (res.data.data.retCode == 300051 || res.data.data.retCode == 300033) {
            this.$message({
              showClose: true,
              message: res.data.data.message,
              type: "warning"
            });
          }
        });
      },
      getMerchantList() {
        this.post('/mall-service/merchant/v1/query/list', {
          roleType: 1
        }).then(res => {
          res = JSON.parse(JSON.stringify(res).replace(/name/g, "label"));
          res = JSON.parse(JSON.stringify(res).replace(/id/g, "value"));
          this.merchantList = res
        })
      },
      setTips(e) {
        this.myForm.merchantId = ''
        if(e == 1 ) {
          this.tip = "仅支持查看授权的货柜和补货功能。"
          this.multiple = false
        }else if (e == 2){
          this.tip = "支持跨商家、跨门店补货、下架、移货、报损、查看货柜订单等功能。"
          setTimeout(() => {
            this.multiple = true;
          });
        }else{
          this.tip = "支持补货、下架、移货、报损、查看货柜订单等全部功能。"
          this.multiple = false
        }
      }
    }
  };
</script>
<style lang="scss">
  .cargoController-detail {
    .el-form {
      margin: 0 auto;
      max-width: 1100px;
    }
  }
  .submitBox {
      text-align: center;
      padding-bottom: 20px;
  }
  .no-autofill-pwd {
  /deep/ .el-input__inner {
    -webkit-text-security: disc !important;
    font-family: text-security-disc !important;
  }
}
</style>