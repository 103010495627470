<template>
  <div class="table-box">
        <app-list :opt="opt" @get="onGet" ref="appList"></app-list>
  </div>
</template>
<script>
export default {
  data() {
    let _this = this;
    return {
      opt: {
        search: [],
        columns: [
        { label: "MAC", key: "mac",align: "center"},
        { label: "设备型号", key: "typeStr",align: "center" },
        { label: "名称", key: "name",align: "center" },
        { label: "IP", key: "ip" ,align: "center"},
        { label: "安装位置", key: "address",align: "center" },
        ],
      },
      roomList: [],
    };
  },
  created() {
    console.log("individualLandlord created!!");
    this.row = this.cache.get("lockInfo");
    
  },
  activated() {
    console.log("individualLandlord activated!!");
     this.row = this.cache.get("lockInfo");
    //  this.$refs.appList.onRefresh();
  },
  mounted(){
   
  },
  methods: {
    onGet(opt) {
      console.log(opt.searchForm);
      let dto = {
        pageNum: opt.skip,
        pageSize: opt.limit,
        counterUserId: this.$route.query.id,
      };
      this.post("/mall-service/counter/v2/counter-user/page", dto, {
        isUseResponse: true
      }).then(res => {
        if(res.data.data.data && res.data.data.data.length >0) {
          res.data.data.data.forEach(item => {
            if([6,7,2001,2004,2005,2006,2007,2008,2009,2010,2011,2012,2013].indexOf(item.type) != -1) {
                if(item.size == 1) {//大
                  if(item.type == 6) {
                    item.typeStr = 'WF-L21BD';
                  }else if(item.type == 7) {
                    item.typeStr = 'WF-L22BD';
                  }
                }else if(item.size == 2) {//小
                    if(item.type == 6) {
                      item.typeStr = 'WF-L21BX';
                    }else if(item.type == 7) {
                      item.typeStr = 'WF-L22BX';
                    }
                }
                if (item.type == 2001) {
                  item.typeStr = 'WF-L27';
                }
                if (item.type == 2004) {
                  item.typeStr = 'WF-L25E';
                }
                if (item.type == 2005) {
                  item.typeStr = 'WF-L25F';
                }
                if (item.type == 2006) {
                  item.typeStr = 'WF-L27N';
                }
                if (item.type == 2007) {
                  item.typeStr = 'WF-L25MK';
                }
                if (item.type == 2008) {
                  item.typeStr = 'WF-L25E85';
                }
                if (item.type == 2009) {
                  item.typeStr = 'WF-L25-6';
                }
                if (item.type == 2010) {
                  item.typeStr = 'WF-L25-12';
                }
                if (item.type == 2011) {
                  item.typeStr = 'WF-L25-18';
                }
                if (item.type == 2012) {
                  item.typeStr = 'WF-L25-24';
                }
                if (item.type == 2013) {
                  item.typeStr = 'WF-L25BMK';
                }
            }else {
              item.typeStr = ['WF-L2K','WF-L21','WF-L22','','','','','WF-L25','WF-L25A','WF-L25B','WF-L25C','WF-L25AF','WF-L25CF','WF-L25AK','WF-L25CK','L25BK','L25-18','L25D','WF-L25AB','T160'][item.type - 1];
            } 
              
          });
        }
        opt.cb(res.data.data);
      });
    },
  }
};
</script>
<style lang="scss">
.table-box{
  height: 620px;
}
</style>