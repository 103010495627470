var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "cargoController-detail" },
    [
      _vm.title
        ? _c("app-header", { attrs: { title: _vm.title, isShowBack: true } })
        : _vm._e(),
      _c(
        "div",
        [
          _c(
            "el-form",
            { ref: "myForm", attrs: { model: _vm.myForm, rules: _vm.rules } },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    "label-width": "150px",
                    label: "货管员类型",
                    prop: "type"
                  }
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { disabled: _vm.notEdit || _vm.notAdd },
                      on: {
                        change: function($event) {
                          _vm.setTips(_vm.myForm.type)
                        }
                      },
                      model: {
                        value: _vm.myForm.type,
                        callback: function($$v) {
                          _vm.$set(_vm.myForm, "type", $$v)
                        },
                        expression: "myForm.type"
                      }
                    },
                    _vm._l(_vm.typeList, function(item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value }
                      })
                    }),
                    1
                  ),
                  _c("div", { staticClass: "select__tip" }, [
                    _vm._v(_vm._s(_vm.tip))
                  ])
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    "label-width": "150px",
                    label: "货管员账号",
                    prop: "userName"
                  }
                },
                [
                  _c("el-input", {
                    attrs: {
                      disabled: _vm.notEdit,
                      placeholder: "请输入货管员账号"
                    },
                    model: {
                      value: _vm.myForm.userName,
                      callback: function($$v) {
                        _vm.$set(_vm.myForm, "userName", $$v)
                      },
                      expression: "myForm.userName"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    "label-width": "150px",
                    label: "货管员密码",
                    prop: "password"
                  }
                },
                [
                  _c("el-input", {
                    staticClass: "no-autofill-pwd",
                    staticStyle: { display: "inline-block" },
                    attrs: {
                      type: "text",
                      disabled: _vm.notEdit,
                      placeholder: "请输入货管员密码"
                    },
                    model: {
                      value: _vm.myForm.password,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.myForm,
                          "password",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "myForm.password"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    "label-width": "150px",
                    label: "货管员姓名",
                    prop: "realName"
                  }
                },
                [
                  _c("el-input", {
                    attrs: {
                      disabled: _vm.notEdit,
                      placeholder: "请输入货管员姓名"
                    },
                    model: {
                      value: _vm.myForm.realName,
                      callback: function($$v) {
                        _vm.$set(_vm.myForm, "realName", $$v)
                      },
                      expression: "myForm.realName"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    "label-width": "150px",
                    label: "货管员手机号",
                    prop: "phone"
                  }
                },
                [
                  _c("el-input", {
                    attrs: {
                      disabled: _vm.notEdit,
                      placeholder: "请输入货管员手机号"
                    },
                    model: {
                      value: _vm.myForm.phone,
                      callback: function($$v) {
                        _vm.$set(_vm.myForm, "phone", $$v)
                      },
                      expression: "myForm.phone"
                    }
                  })
                ],
                1
              ),
              _vm.showMerchant
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        "label-width": "150px",
                        label: "商家名称",
                        prop: "merchantId"
                      }
                    },
                    [
                      !_vm.multiple
                        ? _c(
                            "el-select",
                            {
                              attrs: {
                                disabled: _vm.notEdit || _vm.notAdd,
                                placeholder: "请选择商家名称"
                              },
                              model: {
                                value: _vm.myForm.merchantId,
                                callback: function($$v) {
                                  _vm.$set(_vm.myForm, "merchantId", $$v)
                                },
                                expression: "myForm.merchantId"
                              }
                            },
                            _vm._l(_vm.merchantList, function(item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item.value }
                              })
                            }),
                            1
                          )
                        : _c(
                            "el-select",
                            {
                              attrs: {
                                multiple: "",
                                disabled: _vm.notEdit || _vm.notAdd,
                                placeholder: "请选择商家名称"
                              },
                              model: {
                                value: _vm.myForm.merchantId,
                                callback: function($$v) {
                                  _vm.$set(_vm.myForm, "merchantId", $$v)
                                },
                                expression: "myForm.merchantId"
                              }
                            },
                            _vm._l(_vm.merchantList, function(item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item.value }
                              })
                            }),
                            1
                          )
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                {
                  attrs: {
                    "label-width": "150px",
                    label: "状态",
                    prop: "state"
                  }
                },
                [
                  _c("el-switch", {
                    attrs: {
                      "active-color": "#13ce66",
                      "inactive-color": "#ff4949",
                      "active-text": "启用",
                      "inactive-text": "禁用"
                    },
                    model: {
                      value: _vm.myForm.state,
                      callback: function($$v) {
                        _vm.$set(_vm.myForm, "state", $$v)
                      },
                      expression: "myForm.state"
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.notEdit,
                      expression: "!notEdit"
                    }
                  ],
                  staticClass: "submitBox"
                },
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: { width: "180px" },
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          _vm.submit()
                        }
                      }
                    },
                    [_vm._v("提交")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm.showCargoInfo
        ? _c(
            "div",
            {
              staticStyle: {
                "font-size": "18px",
                margin: "100px 0 6px",
                "padding-left": "50px",
                color: "#303133"
              }
            },
            [_vm._v("管理的货柜信息")]
          )
        : _vm._e(),
      _vm.showCargoInfo ? _c("keep-alive", [_c("cargoInfo")], 1) : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }